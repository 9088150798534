<template>
  <div class="register">
    <div class="form" v-if="!is_ocr_license_img">
      <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >
        上传营业执照
      </van-divider>
      <!-- <div  style="
          height: 2rem;
          line-height:2rem;
          background: rgba(216, 216, 216, 0.37);
          padding-left: 0.3rem;
          margin-bottom: 0.35rem;
          font-size: 0.3rem;
          color: #ffffff;
          border-radius: 0.2rem;
        ">
        <van-row>
              <van-col span="6"><div>营业执照</div></van-col>
              <van-col span="18" style="text-align:center;">
                <van-uploader
                  max-count="1"
                  v-model="fileList"
                  :before-delete="onDeleteImageUrl"
                  preview-full-image
                  accept="image/*"
                  :after-read="fnUploadRequest"
                />
              </van-col>
            </van-row>
        
      </div> -->
      <van-row v-if="!is_ocr_license_img">
        <van-col>
          <div style="color: red; font-size: 16px; margin-top: 0.8rem">*</div>
        </van-col>
        <van-col span="23">
          <div
            class="item"
            style="
              height: 2rem;
              width: 100%;
              background: rgba(216, 216, 216, 0.37);
              padding-left: 0.3rem;
            "
          >
            <van-row>
              <van-col span="18"><div>营业执照</div></van-col>
              <van-col span="6">
                <van-uploader
                  max-count="1"
                  v-model="fileList"
                  :before-delete="onDeleteImageUrl"
                  preview-full-image
                  accept="image/*"
                  :after-read="fnUploadRequest"
                />
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row>
      <div class="submit"><button @click="ocr_license_img">下一步</button></div>
    </div>
    <div class="form" v-if="is_ocr_license_img">
      <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >
        企业信息
      </van-divider>
      <!-- <van-divider content-position="left" dashed>选择地区</van-divider> -->
      <div class="form-title">选择地区</div>
      <div class="item" @click="showArea = true">
        <div style="color: red">*</div>
        <input
          readonly
          type="text"
          name="name"
          placeholder="请选择地区"
          autocomplete="off"
          class="name"
          v-model="ssx"
        />
      </div>
      <div class="form-title">旅行社名称</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          placeholder="请输入旅行社名称"
          autocomplete="off"
          class="name"
          v-model="company.name"
        />
      </div>
      <div class="form-title">旅行社地址</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          placeholder="请输入旅行社地址"
          autocomplete="off"
          class="name"
          v-model="company.address"
        />
      </div>
      <div class="form-title">旅行社英文名称</div>
      <div class="item">
        <div style="color: red">&nbsp;&nbsp;</div>
        <input
          type="text"
          name="name"
          placeholder="请输入旅行社英文名称"
          autocomplete="off"
          class="name"
          v-model="company.name_en"
        />
      </div>
      <div class="form-title">许可证号</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          placeholder="请输入旅行社许可证号"
          autocomplete="off"
          class="name"
          v-model="company.license_no"
        />
      </div>
      <div class="form-title">发证单位</div>
      <div class="item">
        <div style="color: red">&nbsp;&nbsp;</div>
        <input
          type="text"
          name="name"
          placeholder="请输入旅行社发证单位"
          autocomplete="off"
          class="name"
          v-model="company.unit"
        />
      </div>
      <div class="form-title">许可文号</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="30"
          placeholder="请输入许可文号"
          autocomplete="off"
          class="name"
          v-model="company.license_file_no"
        />
      </div>

      <div class="form-title">企业法人</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="11"
          placeholder="请输入企业法人"
          autocomplete="off"
          class="name"
          v-model="company.legal_person"
        />
      </div>
      <div class="form-title">法人手机号</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="11"
          placeholder="请输入法人手机号"
          autocomplete="off"
          class="name"
          v-model="company.telephone"
        />
      </div>
      <div class="form-title">统一信用代码</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="18"
          placeholder="请输入统一信用代码"
          autocomplete="off"
          class="name"
          v-model="company.social_credit_card"
        />
      </div>
      <!-- <van-row>
        <van-col>
          <div style="color: red; font-size: 16px; margin-top: 0.8rem">*</div>
        </van-col>
        <van-col span="23">
          <div
            class="item"
            style="
              height: 2rem;
              background: rgba(216, 216, 216, 0.37);
              padding-left: 0.3rem;
            "
          >
            <van-row>
              <van-col span="18"><div>营业执照</div></van-col>
              <van-col span="6">
                <van-uploader
                  max-count="1"
                  v-model="fileList"
                  :before-delete="onDeleteImageUrl"
                  preview-full-image
                  accept="image/*"
                  :after-read="fnUploadRequest"
                />
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row> -->
      <!-- <van-row
        
      >
        <van-col>
          <div style="color:red;font-size:16px;margin-top: 0.4rem">*</div>
        </van-col>
        <van-col span="23" style="
          height: 1rem;
          line-height:1rem;
          background: rgba(216, 216, 216, 0.37);
          padding-left: 0.3rem;
          margin-bottom: 0.35rem;
          font-size: 0.3rem;
          color: #ffffff;
          border-radius: 0.425rem;
        ">
            <van-row>
              <van-col span="6"><div>营业期限</div></van-col>
              <van-col span="18" >
               <van-radio-group v-model="radio" direction="horizontal" style="height:1rem;line-height:1rem;color:#fff !important;">
                <van-radio name="1"> 非长期</van-radio>
                <van-radio name="2">长期</van-radio>
              </van-radio-group>
            </van-col>
            </van-row>
         
        </van-col>
      </van-row> -->
      <!-- <div class="item" @click="showStartDate = true" v-if="radio=='1'">
        <div style="color: red">*</div>
        <input
          readonly
          type="text"
          name="name"
          maxlength="30"
          placeholder="请选择营业开始时间"
          autocomplete="off"
          class="name"
          v-model="start_date"
        />
      </div> -->
      <!-- <div class="item" @click="showEndDate = true" v-if="radio=='1'">
        <div style="color: red">*</div>
        <input
          readonly
          type="text"
          name="name"
          maxlength="30"
          placeholder="请选择营业结束时间"
          autocomplete="off"
          class="name"
          v-model="end_date"
        />
      </div> -->
      <!-- <van-divider content-position="left" dashed>成立日期</van-divider> -->
      <div class="form-title">成立日期</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="30"
          placeholder="请输入成立日期"
          autocomplete="off"
          class="name"
          v-model="company.establish_date"
        />
      </div>
      <div class="form-title">营业截止日期</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="30"
          placeholder="请输入营业截止日期"
          autocomplete="off"
          class="name"
          v-model="company.valid_period"
        />
      </div>
      <div class="form-title">质保金金额（万元）</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="10"
          placeholder="请输入质保金金额（万元）"
          autocomplete="off"
          class="name"
          v-model="company.deposit"
        />
      </div>
      <div class="form-title">质保银行</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="18"
          placeholder="请输入质保银行"
          autocomplete="off"
          class="name"
          v-model="company.deposit_bank"
        />
      </div>
      <!-- <van-divider content-position="left" dashed>质保凭条</van-divider> -->
      <div class="form-title">质保凭条</div>
      <van-row>
        <van-col>
          <div style="color: red; font-size: 16px; margin-top: 0.8rem">*</div>
        </van-col>
        <van-col span="23">
          <div
            class="item"
            style="
              height: 2rem;
              width: 100%;
              background: rgba(216, 216, 216, 0.37);
              padding-left: 0.3rem;
            "
          >
            <van-row>
              <van-col span="18"><div>质保凭条</div></van-col>
              <van-col span="6">
                <van-uploader
                  max-count="1"
                  v-model="fileList1"
                  :before-delete="onDeleteImageUrl1"
                  preview-full-image
                  accept="image/*"
                  :after-read="fnUploadRequest1"
                />
              </van-col>
            </van-row>
          </div>
        </van-col>
      </van-row>
      <!-- <van-divider content-position="left" dashed>企业联系人</van-divider> -->
      <div class="form-title">企业联系人</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="18"
          placeholder="请输入企业联系人"
          autocomplete="off"
          class="name"
          v-model="company.contact_person"
        />
      </div>
      <!-- <van-divider content-position="left" dashed>联系电话</van-divider> -->
      <div class="form-title">联系电话</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="11"
          placeholder="请输入联系电话"
          autocomplete="off"
          class="name"
          v-model="company.contact_mobile"
        />
      </div>
      <!-- <van-divider content-position="left" dashed>经营场所</van-divider> -->
      <div class="form-title">经营场所</div>
      <div class="item">
        <div style="color: red">&nbsp;&nbsp;</div>
        <input
          type="text"
          name="name"
          maxlength="18"
          placeholder="请输入经营场所"
          autocomplete="off"
          class="name"
          v-model="company.place_of_business"
        />
      </div>
      <!-- <van-divider content-position="left" dashed>传真</van-divider> -->
      <div class="form-title">传真</div>
      <div class="item">
        <div style="color: red">&nbsp;&nbsp;</div>
        <input
          type="text"
          name="name"
          maxlength="13"
          placeholder="请输入传真"
          autocomplete="off"
          class="name"
          v-model="company.fax"
        />
      </div>

      <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
      >
        个人信息
      </van-divider>
      <!-- <van-divider content-position="left" dashed>用户名称</van-divider> -->
      <div class="form-title">用户名称</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="11"
          placeholder="请输入用户名称"
          autocomplete="off"
          class="name"
          v-model="user.username"
        />
      </div>
      <div class="form-title">登录手机号</div>
      <div class="item">
        <div style="color: red">*</div>
        <input
          type="text"
          name="name"
          maxlength="11"
          placeholder="请输入登录手机号"
          autocomplete="off"
          class="name"
          v-model="user.mobile"
        />
      </div>
      <!-- <div class="item" v-if="type == 1">
        <input
          type="text"
          name="vcode"
          maxlength="6"
          placeholder="请输入验证码"
          autocomplete="off"
          class="vcode"
          v-model="user.code"
        />
        <span id="vcode" v-if="!disabled" @click="get_sms_codes">{{
          btnTitle
        }}</span>
        <span id="vcode" v-if="disabled">{{ btnTitle }}</span>
      </div> -->
      <!-- <van-divider content-position="left" dashed>登录密码</van-divider> -->
      <div class="form-title">登录密码</div>
      <div class="item" v-if="type == 2">
        <div style="color: red">*</div>
        <input
          type="password"
          name="password"
          placeholder="请输入登录密码"
          autocomplete="off"
          class="password"
          v-model="user.password"
        />
      </div>
      <!-- <van-divider content-position="left" dashed>确认密码</van-divider> -->
      <div class="form-title">确认密码</div>
      <div class="item" v-if="type == 2">
        <div style="color: red">*</div>
        <input
          type="password"
          name="password"
          placeholder="请确认确认密码"
          autocomplete="off"
          class="password"
          v-model="user.password_confirmation"
        />
      </div>

      <div class="submit"><button @click="do_register">立即注册</button></div>
    </div>

    <van-popup v-model="showArea" position="bottom">
      <van-cascader
        active-color="#436EF0"
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="areaList"
        @close="showArea = false"
        @finish="onConfirm"
      />
    </van-popup>
    <van-popup v-model="showStartDate" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择营业开始日期"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="showStartDate = false"
        @confirm="onStartDateConfirm"
      />
    </van-popup>
    <van-popup v-model="showEndDate" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择营业结束日期"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="showEndDate = false"
        @confirm="onEndDateConfirm"
      />
    </van-popup>
    <van-popup
      v-model="show"
      closeable
      close-icon="close"
      :style="{ height: '30%' }"
      position="bottom"
    >
      <div
        class="select-guide-item"
        v-for="(i, index) in companies"
        :key="index"
        @click="selectAgency(i)"
      >
        {{ i.plan_name }}
      </div>
    </van-popup>
    <div class="footer">
      <div class="question" @click="onQuestionClick">已有账号？去登录</div>
      <p>
        注册使用就表示同意用户协议<a
          href="javascript:;"
          @click="onAgreementClick"
          >条款和隐私</a
        >政策
      </p>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import cookies from "../../utils/cookies";
import oss from "@/utils/oss";
export default {
  name: "register",
  components: {},
  data() {
    return {
      radio: "1",
      is_ocr_license_img: false,
      fileList: [],
      fileList1: [],
      fileList2: [],
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(2100, 10, 1),
      currentDate: new Date(2021, 0, 17),
      showStartDate: false,
      showEndDate: false,
      cascaderValue: "",
      fieldValue: "",
      mobile: "", //手机号
      verifyCode: "", //验证码
      showArea: false,
      password: "",
      btnTitle: "获取验证码",
      text: "请选择旅行社",
      disabled: false, //是否可点击
      errors: {}, //验证提示信息
      show: false,
      companies: [],
      start_date: "",
      end_date: "",
      company: {
        name: "",
        name_en: "",
        address: "",
        license_no: "",
        license_file_no: "",
        unit: "",
        telephone: "",
        legal_person: "",
        place_of_business: "",
        fax: "",
        province_code: "",
        city_code: "",
        district_code: "",
        license_img: "", //营业执照
        deposit: "", //质保金
        deposit_bank: "", //质保银行
        deposit_receipt: "", //质保凭条照片
        contact_person: "", //联系人
        contact_mobile: "", //联系电话
        valid_period: "", //营业截止日期
        establish_date: "", //成立日期
        // code:""
      },
      user: {
        mobile: "",
        password: "",
        username: "",
        avatar: "",
        password_confirmation: "",
      },
      repassword: "",
      type: 2,
      areaList: [],
      ssx: "",
      active: 0,
      license_img_url: "",
    };
  },
  created() {
    this.get_region_data();
  },
  methods: {
    do_next() {
      this.is_ocr_license_img = true;
    },
    async ocr_license_img() {
      const license_img_url = this.company.license_img;
      if (license_img_url) {
        Toast.loading({
          message: "识别中...",
          forbidClick: true,
        });
        const res = await this.$apis.ocr_license_img(license_img_url);
        if (res.data.success) {
          this.is_ocr_license_img = true;
          console.log(res.data);
          this.company.address = res.data.address;
          this.company.name = res.data.name;
          this.company.legal_person = res.data.person;
          this.company.social_credit_card = res.data.reg_num;
          this.company.establish_date = res.data.establish_date;
          // this.company.valid_period = res.data.valid_period
          this.company.valid_period =
            res.data.valid_period == "2999-12-31"
              ? "长期"
              : res.data.valid_period;
          Toast.clear();
        } else {
          Toast("您上传的营业执照无法识别，请重新上传");
          return false;
        }
      } else {
        Toast("请上传营业执照");
        return false;
      }
    },
    getDateTime(date) {
      return new Date(date.replace(/-/g, "/")).getTime();
    },
    onStartDateConfirm(value) {
      console.log(this.formatDate(value));
      this.start_date = this.formatDate(value);
      this.showStartDate = false;
    },
    onEndDateConfirm(value) {
      console.log(this.formatDate(value));
      this.end_date = this.formatDate(value);
      this.showEndDate = false;
    },
    onStartDateCancel() {
      this.showStartDate = false;
    },
    onEndDateCancel() {
      this.showEndDate = false;
    },
    formatDate(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    onDeleteImageUrl() {
      this.company.license_img = "";
      this.fileList = [];
    },
    onDeleteImageUrl1() {
      this.company.deposit_receipt = "";
      this.fileList1 = [];
    },
    onDeleteImageUrl2() {
      this.license_img_url = "";
      this.fileList2 = [];
    },
    fnUploadRequest(option) {
      option.status = "uploading";
      option.message = "上传中...";
      const _self = this;
      oss.ossUploadFile(option).then((res) => {
        _self.company.license_img = res.fileUrl[0].split("?")[0];
        option.status = "success";
        _self.fileList = [
          {
            url: res.fileUrl[0].split("?")[0],
          },
        ];
        Toast.clear();
      });
    },
    fnUploadRequest1(option) {
      option.status = "uploading";
      option.message = "上传中...";
      const _self = this;
      oss.ossUploadFile(option).then((res) => {
        _self.company.deposit_receipt = res.fileUrl[0].split("?")[0];
        option.status = "success";
        _self.fileList1 = [
          {
            url: res.fileUrl[0].split("?")[0],
          },
        ];
        Toast.clear();
      });
    },
    fnUploadRequest2(option) {
      option.status = "uploading";
      option.message = "上传中...";
      const _self = this;
      oss.ossUploadFile(option).then((res) => {
        _self.license_img_url = res.fileUrl[0].split("?")[0];
        option.status = "success";
        _self.fileList2 = [
          {
            url: res.fileUrl[0].split("?")[0],
          },
        ];
        Toast.clear();
      });
    },
    onAgreementClick() {
      this.$router.push("/agreement");
    },

    onPrivacyClick() {
      this.$router.push("/privacy");
    },
    onQuestionClick() {
      this.$router.push("/login");
    },
    selectAgency(item) {
      this.show = false;
      this.user.userable_id = item.id;
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },
    onConfirm({ selectedOptions }) {
      this.ssx = selectedOptions.map((option) => option.text).join("/");
      this.company.province_code = selectedOptions[0].value;
      this.company.city_code = selectedOptions[1].value;
      this.company.district_code = selectedOptions[2].value;

      this.showArea = false;
    },
    async get_region_data() {
      const res = await this.$apis.get_region_data();
      this.areaList = res.data;

      // res.data.forEach()
    },
    //判断社会信用代码
    CheckSocialCreditCode(Code) {
      var patrn = /^[0-9A-Z]+$/;
      //18位校验及大写校验
      if (Code.length != 18 || patrn.test(Code) == false) {
        Toast("不是有效的统一社会信用代码");
        return false;
      } else {
        var Ancode; //统一社会信用代码的每一个值
        var Ancodevalue; //统一社会信用代码每一个值的权重
        var total = 0;
        var weightedfactors = [
          1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28,
        ]; //加权因子
        var str = "0123456789ABCDEFGHJKLMNPQRTUWXY";
        //不用I、O、S、V、Z
        for (var i = 0; i < Code.length - 1; i++) {
          Ancode = Code.substring(i, i + 1);
          Ancodevalue = str.indexOf(Ancode);
          total = total + Ancodevalue * weightedfactors[i];
          //权重与加权因子相乘之和
        }
        var logiccheckcode = 31 - (total % 31);
        if (logiccheckcode == 31) {
          logiccheckcode = 0;
        }
        var Str =
          "0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y";
        var Array_Str = Str.split(",");
        logiccheckcode = Array_Str[logiccheckcode];

        var checkcode = Code.substring(17, 18);
        if (logiccheckcode != checkcode) {
          Toast("不是有效的统一社会信用代码");
          return false;
        } else {
          console.info("yes");
        }
        return true;
      }
    },
    //  判断传真
    isTel(s) {
      //国家代码(2到3位)-区号(2到3位)-电话号码(7到8位)-分机号(3位)"
      var pattern = /^(\d{3,4}-)?\d{7,8}$/;
      //var pattern =/(^[0-9]{3,4}\-[0-9]{7,8}$)|(^[0-9]{7,8}$)|(^\([0-9]{3,4}\)[0-9]{3,8}$)|(^0{0,1}13[0-9]{9}$)/;

      if (!pattern.exec(s)) {
        return false;
      } else {
        return true;
      }
    },
    //判断中文
    vaildZH(str) {
      let regAccountCN = /[\u4e00-\u9fa5]+/g;
      if (regAccountCN.test(str)) {
        return false;
      } else {
        return true;
      }
    },
    //判断非法字符
    validFF(str) {
      let regAccount = /[@#\$%\^&\*\s+]+/g;
      if (regAccount.test(str)) {
        return false;
      } else {
        return true;
      }
    },
    myIsNaN(value) {
      return typeof value === "number" && !isNaN(value);
    },
    async do_register() {
      if (this.company.name == "") {
        Toast("请填写旅行社名称");
        return false;
      }
      if (this.company.name.length > 20) {
        Toast("旅行社名称太长");
        return false;
      }
      if (this.company.province_code == "") {
        Toast("请选择地区");
        return false;
      }
      if (!this.company.address) {
        Toast("请输入旅行社地址");
        return false;
      }
      if (!this.company.license_file_no) {
        Toast("请输入许可文号");
        return false;
      }
      if (this.company.license_file_no) {
        if (!this.validFF(this.company.license_file_no)) {
          Toast("许可文号包含非法字符");
          return false;
        }
      }
      if (!this.company.license_no) {
        Toast("请输入许可证号");
        return false;
      }
      if (this.company.license_no) {
        if (!this.validFF(this.company.license_no)) {
          Toast("许可证号包含非法字符");
          return false;
        }
        if (!this.vaildZH(this.company.license_no)) {
          Toast("许可证号不能有中文");
          return false;
        }
      }
      if (this.company.legal_person == "") {
        Toast("请填写企业法人");
        return false;
      }
      if (this.company.legal_person.length > 20) {
        Toast("法人名称太长");
        return false;
      }
      if (this.company.legal_person) {
        if (!this.validFF(this.company.legal_person)) {
          Toast("法人名称包含非法字符");
          return false;
        }
      }
      if (!this.company.telephone) {
        Toast("请输入法人手机号");
        return false;
      }
      if (this.company.telephone) {
        if (!this.validatemobilePublic(this.company.telephone)) {
          Toast("请输入正确的法人手机号");
          return false;
        }
      }
      if (!this.company.social_credit_card) {
        Toast("请输入统一信用代码");
        return false;
      }
      if (this.company.social_credit_card) {
        if (!this.CheckSocialCreditCode(this.company.social_credit_card)) {
          return false;
        }
      }
      if (this.company.fax) {
        if (!this.isTel(this.company.fax)) {
          Toast("请输入正确的传真");
          return false;
        }
      }
      //license_img
      if (!this.company.license_img) {
        Toast("请上传营业执照");
        return false;
      }
      // if(this.radio=='1'){
      //   if(this.getDateTime(this.start_date)>=this.getDateTime(this.end_date)){
      //     Toast("营业结束时间不能小于营业开始时间");
      //     return false;
      //   }

      //   this.company.business_term = this.start_date+'至'+this.end_date
      // }
      // if(this.radio=='2'){
      //    this.company.business_term = "长期"
      // }
      if (this.company.business_term == "") {
        Toast("请填写营业期限");
        return false;
      }
      if (!this.company.deposit) {
        Toast("请填写质保金");
        return false;
      }
      if (this.myIsNaN(this.company.deposit)) {
        Toast("请填写正确的质保金");
        return false;
      }
      if (this.company.deposit <= 0) {
        Toast("质保金不能小于或者等于0");
        return false;
      }
      if (this.company.deposit) {
        if (this.company.deposit[0] == ".") {
          Toast("质保金输入有误");
          return false;
        }
        if (this.company.deposit[this.company.deposit.length - 1] == ".") {
          Toast("质保金输入有误");
          return false;
        }
        if (this.company.deposit.split(".").length - 1 > 1) {
          Toast("质保金输入有误");
          return false;
        }
      }
      if (this.company.deposit) {
        if (!this.validFF(this.company.deposit)) {
          Toast("质保金包含非法字符");
          return false;
        }
      }
      if (!this.company.deposit_bank) {
        Toast("请填写质保银行");
        return false;
      }
      if (this.company.deposit_bank) {
        if (!this.validFF(this.company.deposit_bank)) {
          Toast("质保银行包含非法字符");
          return false;
        }
      }
      if (!this.company.deposit_receipt) {
        Toast("请上传质保凭条照片");
        return false;
      }
      if (!this.company.contact_person) {
        Toast("请填写联系人");
        return false;
      }
      if (this.company.contact_person) {
        if (!this.validFF(this.company.contact_person)) {
          Toast("联系人包含非法字符");
          return false;
        }
      }
      if (!this.company.contact_mobile) {
        Toast("请输入联系电话");
        return false;
      }
      if (this.company.contact_mobile) {
        if (!this.validatemobilePublic(this.company.contact_mobile)) {
          Toast("请输入正确的联系电话");
          return false;
        }
      }

      if (!this.user.username) {
        Toast("请填写用户名称");
        return false;
      }
      if (this.user.username.length > 10) {
        Toast("用户名称太长");
        return false;
      }
      if (this.user.mobile == "") {
        Toast("请填写登录手机号");
        return false;
      }
      if (this.user.password == "") {
        Toast("请填写登录密码");
        return false;
      }
      if (this.user.password.length < 6) {
        Toast("密码长度必须大于6位");
        return false;
      }
      if (this.user.password_confirmation == "") {
        Toast("请填写确认密码");
        return false;
      }
      if (this.user.password_confirmation.length < 6) {
        Toast("密码长度必须大于6位");
        return false;
      }
      if (this.user.password != this.user.password_confirmation) {
        Toast("两次密码输入不一致");
        return false;
      }

      if (this.validatemobile()) {
        const _self = this;
        const res = await this.$apis.register({
          company: this.company,
          user: this.user,
        });

        if (res.status == 200) {
          Toast("注册成功!");
          this.$router.push("/login");
        }
      }
    },
    async get_sms_codes() {
      const _self = this;
      if (this.validatemobile()) {
        const res = await this.$apis.sms_codes(_self.user.mobile);
        if (res.status == 200) {
          this.validateBtn();
          Toast("验证码已发送到您的手机，请注意查收!");
        } else {
          Toast("发送失败!");
        }
      }
    },
    validateBtn() {
      // this.get_sms_code()
      //倒计时
      this.show = false;
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    validatemobile() {
      // return true;
      //判断输入的手机号是否合法
      if (!this.user.mobile) {
        Toast("请输入手机号");
        return false;
      } else if (!/^1[345678]\d{9}$/.test(this.user.mobile)) {
        Toast("请输入正确的手机号!");
        return false;
      } else {
        this.errors = {};
        return true;
      }
    },
    validatemobilePublic(mobile) {
      if (!/^1[345678]\d{9}$/.test(mobile)) {
        // Toast("请输入正确的手机号");
        return false;
      } else {
        this.errors = {};
        return true;
      }
    },
    async select_companies() {
      const res = await this.$apis.select_companies();
      this.companies = res.data;
    },
  },
};
</script>
<style scoped>
@import "./style.css";
.form-title {
  color: #ced4e2;
  font-size: 14px;
  margin-bottom: 10px;
}
</style>